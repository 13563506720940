import React from "react";

import Logo from "../components/Logo";
import ContactForm from "../components/ContactForm";

import "../components/Layout/layout.scss";
import "../styles/form.scss";
import "../styles/footer.scss";
import "../styles/messageConfirm.scss";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import comp2 from "../images/compressed/comp2.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iframe: {
    width: "400px",
    height: "315px",
    [theme.breakpoints.up("md")]: {
      width: "760px",
      height: "515px",
    },
  },
}));

function MetaTags() {
  return (
    <Helmet>
      <title>{`Pantano Music - Homepage`}</title>
      <meta
        name="description"
        content="Welcome to Pantano music official website"
      />
      <meta property="og:title" content="Pantano Music - Homepage" />
      <meta
        property="og:description"
        content="Welcome to Pantano music official website"
      />
      <meta property="og:image" content={comp2} />
      <meta property="og:url" content="https://www.pantanomusic.com/" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}
function VideoIframe() {
  const classes = useStyles();
  return (
    <section aria-label="Featured video" style={{ margin: "2rem 0" }}>
      <iframe
        className={classes.iframe}
        src="https://www.youtube.com/embed/qxQdfS2Ja1w?autoplay=1"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </section>
  );
}

const App = () => {
  return (
    <Layout>
      <MetaTags />
      <VideoIframe />
      <ContactForm />
      <Logo />
    </Layout>
  );
};

export default App;
